

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Modal from 'antd';
import Select, { Option } from './Select';
import Text from './Text';
import '../assets/styles/navbar.less';
import '../assets/bg.png';
import MediaHeader from './MediaHeader';

function Navbar({ setModalOpen, refWork, refAbout, refHome, refContact }) {
  const history = useHistory();
  const [transparent, setTransparent] = useState(false);
  const [value, setValue] = useState(history.location.pathname ? history.location.pathname.split('/')[1] || 'home' : 'home');
  const handleScrollTo = (elRef) => {
    // Incase the ref supplied isn't ref.current
    if (elRef) {
      const el = elRef.current ? elRef.current : elRef
      // Scroll the element into view
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

  }

  const onSelect = (select) => {
    switch (select) {
      case 'work':
        history.push('/work');
        handleScrollTo(refWork);
        setValue(select);
        break;
      case 'about':
        history.push('/about');
        handleScrollTo(refAbout);
        setValue(select);
        break;
      case 'home':
        history.push('/');
        handleScrollTo(refHome);
        setValue(select);
        break;
      case 'contact':
        history.push('/contact');
        handleScrollTo(refContact);
        setValue(select);
        break;
        // setModalOpen(true);
    }
  }

  useEffect(() => {
    switch (history.location.pathname) {
      case '/work':
        handleScrollTo(refWork);
        break;
      case '/about':
        handleScrollTo(refAbout);
        break;
    }
    window.addEventListener("scroll", () => handleScroll());
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 40) {
      setTransparent(true);
    }
    if (window.pageYOffset < 40) {
      setTransparent(false);
    }
  }

  return (
    <>
      <div className="navbar-top">
        <div className="navbar-background" />
        {/* <MediaHeader /> */}
      </div>
      <div className="navbar" >
        <div onClick={() => history.push("/")} style={{ cursor: 'pointer' }} className={transparent ? "crop" : "no-crop"} ><img className="logo" src="pineapp.png" /></div>
        <Select type="home" value={value} onSelect={onSelect}>
          <Option value="home"><Text>Home</Text></Option>
          <Option value="about"><Text>About</Text></Option>
          <Option value="work"><Text>Work</Text></Option>
          <Option value="contact"><Text>Contact</Text></Option>
          {/* <Option value="personal-website"><Text>Website</Text></Option> */}
        </Select>
      </div>
      </>
  );
}

export default Navbar;
