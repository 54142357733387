

import React from 'react';
import '../assets/styles/text.less';
function Text(props) {
  return (
    <div style={props.style} className={`text ${props.size || "s"}`}>
      {props.children}
    </div>
  );
}

export default Text;
