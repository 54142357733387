import React, { useEffect, useState } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import './assets/styles/App.less'
import Home from './containers/Home';
import Login from './containers/Login';
import Example from './containers/Example';
import KoronaAvi from './containers/KoronaAvi';
import PersonalWebsite from './containers/PersonalWebsite';
import CookieModal from './components/CookieModal';
import { CookiesProvider } from "react-cookie";

function App() {


  return (
    <div style={{ height: '100%' }}>
      <CookiesProvider>
      {/* {this.props.appInit &&  */}
      <BrowserRouter>
        <Switch>
          {/* <Route exact path='/login' component={Login} />  */}
          <Route exact path='/' component={Home} />
          <Route exact path='/work' component={Home} />
          <Route exact path='/about' component={Home} />
          <Route exact path='/contact' component={Home} />
          <Route exact path='/korona-avi' component={KoronaAvi} />
          <Route exact path='/example' component={Example} />
          {/* <Route exact path='/personal-website' component={PersonalWebsite} /> */}
          {/* <Route component={NotFound} /> */}
        </Switch>
      </BrowserRouter>
     <CookieModal/>
     </CookiesProvider>
      {/* } */}
    </div>
  )
}

export default App;
