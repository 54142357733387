import React from 'react';
import { Button, Switch, Modal, Divider } from 'antd';

import { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import "../assets/styles/cookies.less"
import { useCookies } from "react-cookie";

function CookieModal({ }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [customize, setCustomize] = useState(false);
    const [analyticsChecked, setAnalyticsChecked] = useState(true);
    const [cookies, setCookie] = useCookies(["websiteCookie"]);
    const scr ='<script></script>'
    const addAnalytics = () =>{
        const script = document.createElement("script");
        script.async =true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=UA-162806369-1"
        document.body.appendChild(script);
        const script2 = document.createElement("script");
        window.dataLayer = window.dataLayer || [];
        function gtag() { 
            dataLayer.push(arguments); 
        }
        gtag("js", new Date());gtag("config", "UA-162806369-1");

    }
    useEffect(() => {
        // await this.props.checkMe()

        if (!cookies.websiteCookie) {
            setModalOpen(true)
        }
        else {
            if (cookies.analytics === "true") {
                addAnalytics();
            }
        }
    }, [])
    const customizeAccept = () => {
        if (analyticsChecked) {
            setCookie("analytics", analyticsChecked, {
                path: "/"
            });
            addAnalytics();
        }
      
        setCookie("websiteCookie", true, {
            path: "/"
        });
        setModalOpen(false);
    }
    const accept = () => {
        setCookie("websiteCookie", true, {
            path: "/"
        });
        setCookie("analytics", true, {
            path: "/"
        });
        setModalOpen(false);
        addAnalytics();
    }
    return (
        <Modal
            title={!customize ? null : <div className="cookies-title" >
                <div style={{ cursor: 'pointer' }} onClick={() => { setCustomize(false) }}>
                    <ArrowLeftOutlined />
                </div><div>Customize</div><div></div>

            </div>}
            footer={
                customize ? <Button className="cookie-btn-primary" onClick={customizeAccept}>Save & Submit</Button> :
                    (<div>
                        <Button className="cookie-btn" onClick={() => setCustomize(true)}>Customize</Button>
                        <Button className="cookie-btn-primary" onClick={accept}>Accept Cookies</Button>
                    </div>)} visible={modalOpen}>
            {customize ?
                (<div className="cookies">


                    <Switch defaultChecked disabled /> Compulsory Cookies
                    <Switch defaultChecked onChange={setAnalyticsChecked} checked={analyticsChecked} /> Google Analytics
                </div>) :
                (<div className="cookies">

                    <img src="cookie.png" />
                    <div className="cookies-title">We Use Cookies</div>
                    <div className="cookies-body">We use cookies to analyze site traffic and make your experience better. </div>
                </div>
                )

            }
        </Modal>
    )


}
export default CookieModal;