import React, { useState } from 'react';
import Text from '../../components/Text';
import { Parallax } from 'rc-scroll-anim';
import '../../assets/styles/about.less';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function About({ history, pageY }) {
  const [value, setValue] = useState("jack");

  return (
    <div className="parallax-bg-mid" >
      <div className={pageY > (window.innerHeight+800) ? "about fixed":"about"}>
       
        <Parallax
          animation={[{ opacity: 1, playScale: [0.2, 0.5] }, { opacity: 0.2, playScale: [0.5, 0.7] }]}
          style={{ opacity: 0.2, margin: '10px auto' }}
        >
        <div className="section-title"><Text size="l">About.</Text></div>
          <div className="about-group">

            <span className={(pageY > (window.innerHeight+600)) ? "pp show" : "pp"}>
              <LazyLoadImage effect="blur" width="100px" src="/pine-2.png" />
            </span>
            <div className="text-group">
              <div className="title">
                <Text size="m">Hi,</Text>
              </div>
              <Text style={{ marginTop: '10px' }}> <b>Pineapp</b> provides
              front-end solutions with <b>React js</b>,
              Mobile solutions with <b>React Native</b>,
              Building <b>personal websites</b>,
              <b> web applications</b> and <b>mobile applications</b>.
              </Text>

              <Text style={{ marginTop: '10px' }}>
                Pineapp was founded by <b>Ege Cetindag</b> who is a computer engineer and a front-end lover, based in London UK.
</Text>

              {/* I am a <b>front-end developer</b>. I studied computer engineering in <b>Istanbul Technical University, Turkey</b>.
                In Feb 2020, I found <b>Pineapp Software Ltd.</b> in the <b>London, UK.</b> 
              </Text>
              <Text style={{marginTop:'10px'}}>
              I like <b>React</b>, I like start-up companies,
                I like inspiring new ideas, different cultures and traveling. 
                I am fluent in <b>English</b>, I can speak a bit <b>German</b> and <b>Korean</b>.
              </Text>
              */}
            </div>
          </div>
        </Parallax>

      </div>
      </div>
  );
}

export default About;
