import React, { useEffect, useState } from 'react';
import { Button, Input, message, Modal } from 'antd';
import Text from '../../components/Text';
import { Parallax } from 'rc-scroll-anim';
import '../../assets/styles/contact.less'

import { useForm, ValidationError } from '@formspree/react';
import TextArea from 'antd/lib/input/TextArea';
function ContactForm() {
  const [state, handleSubmit] = useForm("mwkazblw");
  console.log('state', state)
  useEffect(()=>{
    if(state.errors && state.errors.length > 0){
      state.errors.map(err => message.error(err.message))
      
    }
  
  },[state.errors])
  if (state.succeeded) {
    return <p style={{marginTop: "20px",
      fontFamily: 'Roboto Condensed',
      fontSize: "1.3rem"}}>Thank you for your message!</p>;
  }
  return (
    <form onSubmit={handleSubmit}>
      <Input
        id="email"
        type="email"
        name="email"
        placeholder="Email"
        className="inputbox"
      />
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <TextArea
        id="message"
        name="message"
        placeholder="Message"
        className="textarea"
      />
      <ValidationError
        prefix="Message"
        field="message"
        errors={state.errors}
      />
      <Button htmlType="submit" disabled={state.submitting}>
        Submit
      </Button>
    </form>
  );
}
function Contact({ history, modalOpen, handleCancel }) {

  return (
    // <Modal className="contact" footer={null} visible={modalOpen} onCancel={handleCancel}>
    <div className="contact-modal">
      <img style={{ left: '3%', bottom: '23px', transform: 'rotate(45deg)' }} className="leaf-class" src="pineapp-leaves.png" />
      <img style={{ left: '3%', top: '17px', transform: 'rotate(135deg)' }} className="leaf-class" src="pineapp-leaves.png" />
      <img style={{ right: '3%', bottom: '23px', transform: 'rotate(-45deg)' }} className="leaf-class" src="pineapp-leaves.png" />
      <Text size="l">Get in touch. </Text>
      <div className="icon-group">
        <div><img style={{ width: '18px' }} src="/email.svg" /> </div>
        <Text>ege@pineappsw.com</Text>
      </div>
      <div className="icon-group">
        <div> <img style={{ width: '18px' }} src="/pin.svg" /> </div>
        <Text>London, UK</Text>
      </div>
      <div className="media-group">
        <a href="https://www.linkedin.com/in/egecetindag/" target="_blank"><img style={{ marginRight: '20px' }} src="/linkedin.svg" /></a>
        <a href="https://github.com/egecetindag" target="_blank"><img src="/logo.svg" /></a>
      </div>
      <ContactForm />
    </div >
    // </Modal>
  );
}

export default Contact;
