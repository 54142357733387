import React, { useState } from 'react';
import Text from '../../components/Text';
import { Parallax } from 'rc-scroll-anim';
import '../../assets/styles/footer.less'
import MediaHeader from '../../components/MediaHeader';
function Footer({ history }) {

  return (
   <div className="footer">
     <img src="pineapp.png"/>
     <div className="media">
     <div className="media-element"><a target="_blank" href="https://github.com/egecetindag"><Text>Github</Text></a></div>
     <a target="_blank" href="https://linkedin.com/in/egecetindag"><Text>Linkedin</Text></a>
     </div>
     <Text>© 2020 pineappsw by Ege Cetindag</Text>
   </div>
  );
}

export default Footer;
