import React, { useEffect, useState } from 'react';
import ReactWhatsapp from 'react-whatsapp';
import Text from '../../components/Text';
import { Parallax } from 'rc-scroll-anim';
import '../../assets/styles/personal-website.less'
import MediaHeader from '../../components/MediaHeader';
import Navbar from '../../components/Navbar';
import Footer from '../Footer';
import {Pro, Basic} from '../../components/PriceTag';
import Theme from './Theme';
import Contact from '../Contact';
function PersonalWebsite() {
    const [modalOpen, setModalOpen] = useState(false);
    useEffect(() => {
        scrollTo(0,0);
    },[])
    
    return (
        <div className="personal-website">
            <div className="bg" />
            <Navbar setModalOpen={setModalOpen} />
            <div className="body">
                <Basic />
                <Pro />

            </div>
       
            <ReactWhatsapp className="website-button" number="90-554-213-9533" message="Merhaba, kişisel websitesiyle ilgili bilgi alabilir miyim?" >
                <img src="/whatsapp.svg" className="whatsapp-icon" />
            </ReactWhatsapp>
            <div className="themes">
            <div className="text-section" style={{padding:0, marginTop:'30px'}}>
                <Text size="xs">For detailed information and ordering, you can contact us via <b>whatsapp</b> support line or <b>hello@pineappsw.com</b>.</Text>
            
                {/* <Text size="xs">Detaylı bilgi ve sipariş için <b>whatsapp</b> destek hattı veya <b>hello@pineappsw.com</b> üzerinden irtibata geçebilirsiniz.</Text> */}
            </div>
            <div className="theme-section">
                    <Text size="m">Basic Plan Default Themes </Text>
                </div>
                <div className="theme-section">
                    <Text size="m">Theme -1 </Text>
                </div>
                <div className="text-section">
                    <Text>You can customize the backgrounds, all articles, and turn off the parts you don't want.</Text>
                    <a href="http://tema1.pineappsw.com" target="_blank">http://tema1.pineappsw.com</a>
                </div>

                <Theme url1="ss/ss11.png" url2="ss/ss12.png" mobile="ss/ss11-m.png" />
                <div className="divider" />

                <div className="theme-section">
                    <Text size="m">Theme -2 </Text>
                </div>
                <div className="text-section">
                    <Text>You can customize the backgrounds, all articles, and turn off the parts you don't want.</Text>
                    <a href="http://tema2.pineappsw.com" target="_blank">http://tema2.pineappsw.com</a>
                </div>
                <Theme url1="ss/ss21.png" url2="ss/ss22.png" mobile="ss/ss21-m.png" />
                <div className="divider" />
                <div className="theme-section">
                    <Text size="m">Theme -3 </Text>
                </div>
                <div className="text-section">
                    <Text>You can customize the backgrounds, all articles, and turn off the parts you don't want.</Text>
                    <a href="http://tema3.pineappsw.com" target="_blank">http://tema3.pineappsw.com</a>
                </div>
                <Theme url1="ss/ss31.png" url2="ss/ss32.png" mobile="ss/ss31-m.png" />

            </div>
            <Footer />
            <Contact handleCancel={() => setModalOpen(false)} modalOpen={modalOpen} />
        </div>
    );
}

export default PersonalWebsite;
