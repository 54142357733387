import React from 'react';

class Triangle {
  constructor(height, width) {
    this.height = height;
    this.width = width;
  }
  calcArea() {
    return this.height * this.width;
  }
}
class Square {
  constructor(r) {
    this.r = r;
  }

  calcArea() {
    return Math.PI * this.r * this.r;
  }
}

function Example({ history }) {

  let q1 = () => {
    console.log('Q1 ------------');
    for (var i = 10; i > 0; i--) {
      console.log(i);
    }
  }

  let q2 = () => {
    console.log('Q2 ------------');
    for (var i = 1; i <= 100; i++) {
      if (i % 3 === 0) {
        console.log('fizz');
      }
      else if (i % 4 === 0) {
        console.log('buzz');
      }
      else {
        console.log(i)
      }

    }
  }
  let q3 = () => {
    for (var i = 1000; i <= 10000; i++) {
      if (Math.sqrt(i) % 1 === 0) {
        console.log(`${i} is a square number`);
      }
    }
    let squareNums = [];
    for (var i = 1; i <= 1000; i++) {
      if (Math.sqrt(i) % 1 === 0) {
        squareNums.push(i);
      }
    }
    squareNums.map((sNum) => {
      squareNums.map((sNum2) => {
        if (sNum + sNum2 === 1000) {
          console.log(`${sNum} and ${sNum2}`)
        }
      })
    })
  }

  let q4 = () => {
    console.log('Q4 -----');
    let triangle = new Triangle(5, 10);
    console.log('Area of triangle ' + triangle.calcArea());
    let square = new Square(10);
    console.log('Area of square ' + square.calcArea());
  }
  let q5 = () => {
    let heightArr = ["6'2", "5'7", "6'1", "5'11"];

    heightArr.map((height) => {
      var parts = height.split("'");
      console.log('part' + parts[0] + 've' + parts[1])
      parts[0] = parseInt(parts[0]);
      parts[1] = parseInt(parts[1]);
    });
  }
  let q6 = (w1, w2) => {
    console.log('Q6 ------------');
    if (w1.length !== w2.length) {
      return false;
    }
    else {
      w1.split('').map(letter1 => {
        let canfind = false;
        w2.split('').map((letter2,i) => {
          if (letter1 === letter2) {
            canfind = true;
          // todo : we should remove the letter we found 
          }
        })
        if (canfind === false) {
          return false;
        }
      })
      return true;
    }
  }
  q1();
  q2();
  q3();
  q4();
  q5();
  console.log(q6('pencil', 'licpen'));
  return (
    <></>
  )



}
export default Example;