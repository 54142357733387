import React, { useState } from 'react';
import {Button} from 'antd';
import Unity, { UnityContent } from "react-unity-webgl";
import '../Home/index';
import '../../assets/styles/koronaAvi.less'
// import  "korona_build/Build/UnityLoader.js";
function KoronaAvi({history}) {
  const unityContent = new UnityContent(
    "korona_build/Build/korona_build.json",
    "korona_build/Build/UnityLoader.js"
  );
   return (
     <div className="korona-page">
       <div className="header">
        <img className="logo" src="pineapp.png" />
         <div className="korona">
           <div className="unity-container">
           {console.log('aaaa', unityContent)}
             {/* <Unity unityContent={unityContent} /> */}
             {/* <div dangerouslySetInnerHTML={{  __html:html}}/> */}
             <iframe src="korona_build/index.html"/>
          </div>
         </div>
       </div>
    </div>
  )
}

export default KoronaAvi;
