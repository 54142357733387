import React, { useState, useEffect, useRef } from 'react';
import { Parallax } from 'rc-scroll-anim';
import Project from '../../components/Project';
import Text from '../../components/Text';
import Texty from 'rc-texty';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'rc-texty/assets/index.css';
import '../../assets/styles/work.less';

function Work({ history, pageY }) {
  const [open, setOpen] = useState(false);
  const outRef = useRef();
  const reactjs = <LazyLoadImage src="/react.svg" />;
  const nextjs = <LazyLoadImage src="/nextjs.svg" />;
  const aws = <LazyLoadImage src="/aws.svg" />;
  const css = <LazyLoadImage src="/css3.svg" />
  const unity = <LazyLoadImage src="/unity.svg" />
  // const html = <LazyLoad src="/html5.svg" />
  const less = <LazyLoadImage src="/less.svg" />
  const redux = <LazyLoadImage src="/redux.svg" />
  const electron = <LazyLoadImage src="/electron.svg" />
  useEffect(() => {
    if(pageY > (window.innerHeight * 2 + 800)){
      setOpen(true);
    }
   
  }, [pageY ]);
  
console.log(pageY, window.innerHeight * 2 + 800)
  return (
    <div className="parallax-bg-bottom" >
      <div className="work" ref={outRef}>
        {/* <div className="section-title"><Text size="l">{open && <Texty duration={1500} type="left" mode="sync">Work.</Texty>}</Text></div>
      <div className="work-group">
        <div>
          <Text>{open && <Texty duration={1000} type="alpha" mode="sync">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ip
            sum has been the industry's standard dummy text ever since the 1500s, when an unknown
             printer took a galley of type and scrambled it to make a type specimen book. It has su
             rvived not only five
           ally unchanged. It</Texty>}</Text>
        </div>
      </div> */}
        <Parallax
          animation={{ opacity: 1, playScale: [0, 0.5] }}
          style={{ opacity: 0, margin: '10px auto' }}
        > <div className="section-title"><Text size="l">Work.</Text></div>
        </Parallax>

        <div className="work-group">

            <div className={open ? "type-group activated" : "type-group"}>

              <div className="work-types">
                {reactjs}
                <Text>ReactJS</Text>
              </div>
              <div className="work-types">
                {css}
                <Text>CSS</Text>
              </div>
              {/* <div className="work-types">
                {html}
                <Text>HTML</Text>
              </div> */}
              <div className="work-types">
                {unity}
                <Text>Unity</Text>
              </div>
              <div className="work-types">
                {less}
                <Text>Less</Text>
              </div>
              <div className="work-types">
                {aws}
                <Text>AWS</Text>
              </div>
              <div className="work-types">
                {nextjs}
                <Text>NextJS</Text>
              </div>
              <div className="work-types">
                {electron}
                <Text>Electron</Text>
              </div>
            </div>
 
          <div className="mt-3">
            {/* <Text size="ml">B2B</Text> */}
          </div>

          <Project
            name="Front-end Development of MottoJoy"
            target="https://mottojoy.com"
            year="2020"
            exp="MottoJoy is a start-up company that sells activity tickets."
            url="mottojoy.jpg"
            icons={[reactjs, nextjs, redux, less, aws]}
          />
          <Project
            name="Website of Baran Akalin"
            target="http://www.baranakalin.com"
            year="2020"
            exp=" Personal website development using React"
            url="baranwebsite.jpg"
            icons={[reactjs, less]}
          />
          <Project
            name="Admin Panel of IUGO Tech"
            target="https://fleet.iugo.tech"
            year="2017"
            exp="IUGO Tech is a fleet car tracking and driver behaviour scoring company."
            url="iugo.jpg"
            icons={[reactjs, redux, css]}
          />
          <Project
            name="Point of Sale"
            target="https://github.com/turkogluc/pointOfSale-Frontend"
            year="2018"
            exp=" Front-end of desktop stock management application"
            url="stock.jpg"
            icons={[electron, redux, css]}
          />
        </div>
      </div>
    </div>
  );
}

export default Work;
