

import React, { useState } from 'react';
import Select, { Option } from './Select';
import { Parallax } from 'rc-scroll-anim';
import Text from './Text';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../assets/styles/work.less';


function MediaHeader({ history, name, year, exp, url, icons, target }) {
  return (
    <div className="project mt-1 mb-2">
      <Text size="m" style={{ alignItems: 'center', display: 'flex' }}>
        <i><b>{name}</b></i><div className="year-work">{year}</div>
      </Text>
      <div className="finished-group">
        <div className="work-image">
          <a style={{ color: 'white' }} href={target} target="_blank" >
            <div className="layer">
              <div className="visit-website">
                <Text>Visit Website</Text>
              </div>
            </div>
          </a>
          <LazyLoadImage
            effect="blur"
            src={url}
          />
        </div>
        <div className="text-group">
          <Text>
            {exp}
          </Text>
          <div className="small-icon">
            {icons.map(icon => icon)}

          </div>
        </div>
      </div>
    </div>


  );
}

export default MediaHeader;

