import React, { useState, useEffect, useRef } from 'react';
import Text from './Text';

import '../assets/styles/select.less'
function SelectComp(props) {
  const outRef = useRef();
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState([]);
  const [selected, setSelected] = useState();
  const [offsetY, setOffsetY] = useState(0);
  useEffect(() => {
    setChildren(props.children.filter(c => props.value !== c.props.value));
    setSelected(props.children.find(c => props.value == c.props.value));
  },[props.value]);
  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll());
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  
  const handleScroll = () =>{
    setOffsetY((oldOffset)=>{
    if(Math.abs(oldOffset  - window.pageYOffset) > 100){
      setOpen(false);
      return window.pageYOffset;
    }
   return oldOffset;});
  
  }

  const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    useEffect(() => {
      document.addEventListener("click", handleClick);
  
      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

    useOutsideClick(outRef, () => {
      setOpen(false);
    });
  return (
    <div ref={outRef} className={`${props.type}-select`}>
      <div className="select-group">
        <div className="select-second-border"/>
        <button className="select-button" onClick={()=>setOpen(!open)}>
          <Text>{selected ? selected.props.children : ""}</Text>
      </button>
      </div>
    <div className={`dropdown-menu ${open? "active" : "hidden"}`}>
      {children.map((c,i) => 
     
        <div style={open ? {transform:`translateY(${i*42}px)`}:{}} className="dropdown-menu-item">
         <button onClick={()=>{setOpen(false);props.onSelect(c.props.value)}}>
         {c.props.children}
         </button>
         </div>
        )}
    </div>
    </div>
  );
}
export function Option(props){
  return(
    <div>props.children</div>
  )
}
export default SelectComp;

// <div className={`${props.type}-select`}>
    //   <div className="select-second-border" />
    //   <Select dropdownClassName={`${props.type}-dropdown ${open}`} onDropdownVisibleChange={a => a ? setOpen("active") : setOpen("hidden")} style={{ width: 120 }} {...props}>
    //     {props.children}
    //   </Select>