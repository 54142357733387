import { Parallax } from 'rc-scroll-anim';
import React, { useState, useEffect } from 'react';
import Select, { Option } from './Select';
import Text from './Text';
import '../assets/styles/header.less';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import animType from 'rc-texty/lib/animTypes';
import Typed from "react-typed";

function Header(props) {
  const [value, setValue] = useState("about");
  const [show, setShow] = useState(false);

  const { pageY } = props;
  const windowH = window.innerHeight;
  useEffect(() => {
    setShow(true);

  }, []);
  return (


    <div className="header">

      <div className="header-banner">
        <div //sag ust
          style={{ top: '15vh'}}
          className="header-title"
        >WE <br /> ARE <br /> PINEAPP.</div>
        <div //sag ust
          className="header-title-right"
        > We<br/> develop 
        <br/>
        <span>
        <Typed
            strings={[
              "Web Apps.",
              "Mobile Apps.",
              "Websites.",
            ]}
            typeSpeed={150}
            backDelay={1100}
            backSpeed={30}
            loop
          />
        </span>
        </div>

        <div className="header-parallax">
          <Parallax //sag ust
            animation={
              { y: -150, rotate: -30, playScale: [0.3, 1.5] }
            }
            style={{ top: '50%', opacity: 1, right: '20%' }}
            className="parallax-img parallax-1"
          />
          <Parallax //sol
            animation={
              { y: '-130px', rotate: 80, playScale: [0.7, 1.7] }
            }
            style={{ top: '25%', opacity: 1, left: '22%' }}
            className="parallax-img parallax-2"
          />
          <Parallax //alt
            animation={{ y: -600, rotate: -180, playScale: [-0.7, 2.3] }}
            style={{ top: '110%', right: '47%', opacity: 1, zIndex: 2 }}
            className="parallax-img parallax-3"
          />
          <Parallax
            animation={{ y: -60, rotate: -30, playScale: [0.7, 1.2] }}
            style={{ top: '20%', opacity: 1, zIndex: 2, transform: 'rotate(-65deg)', left: '30%', height: '67px', width: '35px' }}
            className="parallax-leaf"
          />

          <Parallax
            animation={{ y: -150, rotate: -40, opacity: 0, playScale: [0.4, 1.4] }}
            style={{ top: '40%', opacity: 1, right: '30%', transform: 'rotate(-35deg)', height: '67px', width: '45px' }}
            className="parallax-leaf"
          />
          <Parallax
            animation={{ y: -100, playScale: [0.5, 1.2] }}
            style={{ top: '55%', opacity: 1, zIndex: 2, transform: 'rotate(-35deg)', left: '50%', height: '67px', width: '50px' }}
            className="parallax-leaf"
          />

          <div >
            <Parallax
              animation={[{ y: 150, playScale: [0.4, 1], opacity: 1 }]}
              className="code-box-shape"
              style={{ opacity: 0 }}
            >
              {/* <Text size="l"><Texty duration={1500} type="left" mode="sync" >Software Solutions.</Texty></Text> */}
            </Parallax>
            {/* <Parallax
          animation={{ y: 20 }}
          className="code-box-shape"
        >
           <Text size="s"><Texty duration={1800} type="top" mode="sync" >we bring software solutions for you.</Texty></Text>
        </Parallax> */}
          </div>
        </div>
        <div
          className="header-text-wrapper"
          style={pageY > (windowH) ? { position: 'fixed', bottom: "auto", top: "10vh" } : {}}
        >
          <div
            className={pageY > (windowH * 4 / 6) ? "header-text-title-2 h-text-t" : "header-text-title h-text-t"}>
            Services.
          </div>
          <div
            className={pageY > (windowH * 4 / 6) ? "header-text-2 h-text" : "header-text h-text"}>
            <div className="service">
              <div className="icon">
                <img src="./global.png"/>
                </div>WEB APPLICATION DEVELOPMENT
                <div class="body-text">We develop modular, sustainable and scalable web applications, using new technologies. </div>
                </div>
            <div className="service"><div className="icon"><img src="./smartphone.png"/></div>MOBILE APPLICATION DEVELOPMENT<div class="body-text">We develop cross-platform mobile applications.</div></div>
            <div className="service"><div className="icon"><img src="./shopping-cart.png"/></div> E-COMMERCE SOLUTIONS<div class="body-text">We provide e-commerce solutions, using platforms like Squarespace, Shopify and Wordpress. </div></div>
            <div className="service"><div className="icon"><img src="./browser.png"/></div> WEB DESIGN<div class="body-text">We design and develop your personal or business websites.</div></div>
          </div>
        </div>
        <div className="header-title">
          <Text size="l"></Text>
          {props.children}
        </div>
      </div>
    </div>

  );
}

export default Header;
