import React, { useState, useRef, useEffect } from 'react';
import Text from '../../components/Text';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import About from '../About';
import Work from '../Work';
import Contact from '../Contact';
import Footer from '../Footer';
import ScrollableSection from 'react-update-url-on-scroll';

import '../../assets/styles/home.less'
import MediaHeader from '../../components/MediaHeader';
function Home({ history }) {
  const refHome = useRef(null);
  const refAbout = useRef(null);
  const refWork = useRef(null);
  const refContact = useRef(null);
  const [value, setValue] = useState(history.location.pathname ? history.location.pathname.split('/')[1] || 'home' : 'home');
  const [modalOpen, setModalOpen] = useState(false);
  const [pageY, setPageY] = useState(0);

  useEffect(() => {

    window.addEventListener('scroll', listenToScroll)
    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  }, []);
  const listenToScroll = () => { setPageY(window.pageYOffset), console.log(window.pageYOffset) }
  return (
    <div ref={refHome} className="home">
      <Navbar  refWork={refWork} refAbout={refAbout} refHome={refHome} refContact={refContact} setModalOpen={setModalOpen}/>
      
      <Header pageY={pageY}/>
      <div style={{ position: 'relative', width:"100%", display:"flex", justifyContent:"center"   }} >
        <div ref={refAbout} ></div>
        <About  pageY={pageY} history={history} />
      </div>
      <div style={{ position: 'relative', width:"100%", display:"flex", justifyContent:"center"   }}>
        <div ref={refWork} ></div>
        <Work pageY={pageY} history={history} />
      </div>
      <div></div>
      <div style={{ position: 'relative', width:"100%",background:'#c6ffe2', borderTop:'10px solid white',display:"flex", justifyContent:"center"   }}>
        <div ref={refContact} ></div>
        <Contact pageY={pageY} history={history} />
      </div>
      <Footer />
      {/* <Contact handleCancel={() => setModalOpen(false)} modalOpen={modalOpen} /> */}
    </div>
  );
}

export default Home;
